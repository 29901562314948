import axios from 'axios';
import configDate from '../utils/config.js';

const axiosInstance = axios.create({
    timeout: 10000000,
    baseURL: configDate.url
})

//http request 拦截器
axiosInstance.interceptors.request.use(
    config => {
        const token = window.sessionStorage.getItem('Token');
        config.headers = {
            "Content-Type": "application/json",
            "token": token
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
/**
 * 响应拦截
 */
axiosInstance.interceptors.response.use(
    response => {
        
        if (response.data.status === 403) {
            router.push({
                path: "/login",
                query: {redirect: router.currentRoute.fullPath}//从哪个页面跳转
            })
        }
        return response;
    },
    error => {
        return Promise.reject(error)
    }
);
export default axiosInstance
