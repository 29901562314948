import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import RouteDate from './config';
import RouteDate from './newConfig';
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(VueRouter)
    //console.log(RouteDate, leix)
let leix = sessionStorage.getItem("Leix")
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: '首页' },
        children: [{
                path: '/',
                name: 'EntryStoma',
                component: () =>
                    import ('../views/WebView/EntryStoma.vue'),
                meta: { title: '首页' },
            },
            {
                path: '/list',
                name: 'list',
                component: () =>
                    import ('../views/WebView/List.vue'),
                meta: {
                    title: '列表',
                },
            },
            {
                path: '/details/:id',
                name: 'details',
                component: () =>
                    import ('../views/WebView/details.vue'),
                meta: { title: '通知正文' },
            },
            {
                path: '/detail/:id',
                name: 'detail',
                component: () =>
                    import ('../views/WebView/detail.vue'),
                meta: {
                    title: '政策正文',
                },
            },
            {
                path: '/searchs',
                name: 'searchs',
                component: () =>
                    import ('../views/WebView/Searchs.vue'),
                meta: { title: '搜索' },
            },
            {
                path: '/noticed/:id',
                name: 'noticed',
                component: () =>
                    import ('../views/WebView/notice'),
                mate: { title: '文章' }
            },
            {
                path: '/declare',
                name: 'declare',
                component: () =>
                    import ('../views/WebView/declare.vue'),
                meta: { title: '公告' },
            },
            {
                path: '/declareitem',
                name: 'declareitem',
                component: () =>
                    import ('../views/WebView/declareitem.vue'),
                meta: { title: '政策申报入口' },
            },
            {
                path: '/talent/policy',
                name: 'talent-policy',
                component: () =>
                    import ('../views/WebView/talentPolicy.vue'),
                meta: { title: '人才政策' },
            },
            {
                path: '/talent/policy/see/:id',
                name: 'talent-policy-see',
                component: () =>
                    import ('../views/WebView/talentPolicySee.vue'),
                meta: { title: '人才政策正文' },
            },
            {
                path: '/declareitem/declarewz/:id',
                name: 'declarewz',
                component: () =>
                    import ('../views/WebView/declarewz.vue'),
                meta: { title: '申报详情' },
            },
            {
                path: "/guide",
                name: 'guide',
                component: () =>
                    import ('../views/WebView/guide'),
                meta: { title: '操作指南' }
            },
            {

                path: '/discuss',
                name: 'discuss',
                component: () =>
                    import ('../views/WebView/discuss.vue'),
                meta: { title: '互动交流' },
            }
        ]
    },
    {
        path: '/main',
        name: 'main',
        component: () =>
            import ('../views/main.vue'),
        meta: { title: '控制面板' },
        children: RouteDate
            //children: typeof(RouteDate) == "undefined"?location.reload():RouteDate
            //children: typeof(RouteDate) == "undefined"?location.reload():(RouteDate[0].name != leix?location.reload():RouteDate)
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login.vue'),
        meta: { title: '登录' },
    },
    {
        path: '/account/register',
        name: 'zhuce',
        component: () =>
            import ('../views/register.vue'),
        meta: { title: '注册' },
    },
    {
        path: '/overview',
        name: 'overview',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/overview'),
        meta: { title: '数据总览' }
    }, {
        path: '*',
        name: 'EntryStoma',
        redirect: Home,
        component: () =>
            import ('../views/WebView/EntryStoma.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
   
    if (!to.name && typeof(to.name) != "undefined" && to.name !== 0) {
        if (leix) {
            if (leix === 'talent') {
                console.log('--------------talent-----')
                next({ path: '/main/talent' })
            } else if (leix === 'enterprise') {
                next({ path: '/main/enterprise' })
                console.log('--------------enterprise-----')
            } else if (leix === 'admin') {
                next({ path: '/main/admin' })
                console.log('--------------admin-----')
            } else {
                next({ path: '/main/worthyManager' })
                console.log('-------worthyManager-----')
            }
        } else {
            window.location.href = '/'
        }
    }
    next()
})

export default router