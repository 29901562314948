<!--
 * @Descripttion: 
 * @Author: 赖鹏辉
 * @Date: 2020-12-08 16:16:08
 * @LastEditors: 赖鹏辉
 * @LastEditTime: 2021-01-12 10:18:26
-->
<!-- 前台公共底部 -->
<template>
  <footer class="footer">
    <div class="center">
      <img src="../../assets/jgdj1.png" class="fl" alt="" />
      <img src="../../assets/jxgan.png" class="fr" alt="" />
      主办: 赣州经济技术开发区党工委人才办
      <span style="margin-left: 16px">电话：8370090<br /></span> Copyright 2020
      All Rights Reserved <br />
      <a target="_blank" href="https://beian.miit.gov.cn/"
        >赣ICP备19001165号-2</a
      >
      网站标识码：3607000000　赣公网安备 36070000000000号
    </div>
  </footer>
</template>

<script>
export default {
  name: "bottom",
};
</script>

<style scoped lang="less">
.footer {
  display: block;
  //position: relative;
  height: 140px;
  background: #fff;

  .center {
    height: 104px;
    width: 784px;
    margin: auto;
    font-size: 14px;
    line-height: 30px;
    padding: 24px 0 0 0;
    img {
      display: block;
      padding: 15px 0 0 0;
    }
  }
}
@import "../../styles/common_responsive.less";
</style>
