<!-- 前台主页 -->
<template>
    <div class="home">
        <Header/>
        <div class="hr-10"></div>
        <div class="mbox">
            <router-view/>
        </div>
        <div class="hr-10"></div>
        <Footer/>
    </div>
</template>

<script>
    //import Edit from '@/components/ControlPanel/edit.vue'
    import Header from '@/components/WebPanel/header.vue'
    import Footer from '@/components/WebPanel/footer.vue'

    export default {
        components: {
            //Edit,
            Header,
            Footer
        }
    }
</script>
<style lang="less" scoped>
    .mbox {
    }
</style>
