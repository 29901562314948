var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heade"},[_c('div',{staticClass:"lins"},[_c('span',{staticClass:"logotext"},[_vm._v("赣州国家高层次人才产业园")]),_c('span',{staticClass:"times",staticStyle:{"padding-left":"32px"}},[_vm._v(_vm._s(_vm.thisTime))]),(_vm.leix)?_c('div',{staticClass:"adminbutton"},[_c('el-button',{staticClass:"login",attrs:{"type":"primary","size":"small","icon":"el-icon-s-platform"},domProps:{"innerHTML":_vm._s(
          _vm.leix === 'admin'
            ? '后台登录(管理员)'
            : _vm.leix === 'talent'
            ? '后台登录(人才)'
            : _vm.leix === 'enterprise'
            ? '后台登录(单位)'
            : '后台登录(乡贤管理)'
        )},on:{"click":_vm.login}}),_c('el-button',{attrs:{"type":"danger","plain":"","size":"small","icon":"el-icon-close"},on:{"click":_vm.signout}},[_vm._v("退出登录")])],1):_c('div',{staticClass:"dlzcan"},[_c('router-link',{attrs:{"to":"/login"}}),_c('a',{staticClass:"zhuc",attrs:{"href":"/?#/account/register"}})],1)]),_c('div',{staticClass:"logo_dlzcbut"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/logo2.png"),"alt":""}}),(this.$route.path !== '/searchs')?_c('div',{staticClass:"fr search-but"},[_c('div',{staticClass:"input-suffix fl"},[_c('el-input',{attrs:{"placeholder":"请输入文章标题","prefix-icon":"el-icon-search"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('el-button',{staticClass:"but fr",attrs:{"type":"primary","icon":"el-icon-search","id":"search"},on:{"click":function($event){return _vm.search()}}})],1):_vm._e()]),_c('div',{staticClass:"navs"},[_c('el-menu',{attrs:{"default-active":_vm.active,"mode":"vertical","background-color":"#2380d7","text-color":"#fff","router":"","active-text-color":"#fff"}},[_c('el-menu-item',{attrs:{"index":"/"}},[_vm._v("首页")]),_c('el-menu-item',{attrs:{"index":"/declare"}},[_vm._v("通知公告")]),_c('el-menu-item',{attrs:{"index":"/talent/policy"}},[_vm._v("人才政策")]),_c('el-menu-item',{attrs:{"index":"/declareitem"}},[_vm._v("政策申报入口")]),_c('el-menu-item',{attrs:{"index":"/guide"}},[_vm._v("操作指南")]),_c('el-menu-item',{attrs:{"index":"/discuss"}},[_vm._v("互动交流")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }