<!-- 前台公共头部 -->
<template>
  <div class="heade">
    <div class="lins">
      <span class="logotext">赣州国家高层次人才产业园</span
      ><span class="times" style="padding-left: 32px">{{ thisTime }}</span>
      <div class="adminbutton" v-if="leix">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-s-platform"
          class="login"
          @click="login"
          v-html="
            leix === 'admin'
              ? '后台登录(管理员)'
              : leix === 'talent'
              ? '后台登录(人才)'
              : leix === 'enterprise'
              ? '后台登录(单位)'
              : '后台登录(乡贤管理)'
          "
        >
        </el-button>
        <el-button
          type="danger"
          plain
          size="small"
          icon="el-icon-close"
          @click="signout"
          >退出登录</el-button
        >
      </div>
      <div class="dlzcan" v-else>
        <router-link to="/login"></router-link>
        <a href="/?#/account/register" class="zhuc"></a>
      </div>
    </div>
    <div class="logo_dlzcbut">
      <img src="../../assets/logo2.png" class="logo" alt />
      <div class="fr search-but" v-if="this.$route.path !== '/searchs'">
        <div class="input-suffix fl">
          <el-input
            placeholder="请输入文章标题"
            prefix-icon="el-icon-search"
            v-model="searchText"
          ></el-input>
        </div>
        <el-button
          type="primary"
          class="but fr"
          icon="el-icon-search"
          @click="search()"
          id="search"
        ></el-button>
      </div>
    </div>
    <div class="navs">
      <el-menu
        :default-active="active"
        mode="vertical"
        background-color="#2380d7"
        text-color="#fff"
        router
        active-text-color="#fff"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/declare">通知公告</el-menu-item>
        <el-menu-item index="/talent/policy">人才政策</el-menu-item>
        <el-menu-item index="/declareitem">政策申报入口</el-menu-item>
        <el-menu-item index="/guide">操作指南</el-menu-item>
        <el-menu-item index="/discuss">互动交流</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "heade",
  data() {
    return {
      leix: sessionStorage.getItem("Leix"),
      user: sessionStorage.getItem("user"),
      searchText: "",
      active: this.$route.path,
      thisTime: "dasda",
    };
  },
  created() {
    this.thisTime = this.$moment().format("YYYY年  MMMDo ");
  },
  methods: {
    search() {
      this.$router.push({
        path: "/searchs",
        query: { searchText: this.searchText || " " },
      });
      this.searchText = "";
    },
    signout() {
      this.$confirm("确定退出系统?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          window.location.reload();
          this.$message({
            type: "success",
            message: "成功退出系统!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出系统",
          });
        });
    },

    login() {
      let path = "/main/" + this.leix;
      let newPage = this.$router.resolve({
        path: path,
        query: { id: this.leix },
      });
      window.open(newPage.href, "_blank");
    },
  },
  watch: {
    $route(to, from) {
      // this.active = "/" + this.$route.path.split("/")[1];
    },
  },
};
</script>

<style scoped lang="less">
.heade {
  height: 265px;
  background: #fff url("../../assets/head.jpg") repeat-x;

  .lins {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 40px;

    .adminbutton {
      float: right;
      margin: 3px 0 0 0;
      .el-button--primary {
        color: #ffffff;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    span {
      height: 40px;
      display: block;
      line-height: 40px;
      float: left;
    }

    .dlzcan {
      position: absolute;
      right: 0;
      top: 50%;
      width: 147px;
      height: 20px;
      margin-top: -10px;
      background: url("../../assets/dlzcan1.png") no-repeat top center;

      a {
        height: 20px;
        width: 50%;
        display: block;
        float: left;
      }

      .zhuc {
        width: 40%;
        float: right;
      }
    }
  }

  .logo_dlzcbut {
    position: relative;
    height: 165px;
    width: 1200px;
    margin: 0 auto;

    .logo {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -27.5px;
    }

    .search-but {
      width: 300px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -20px;

      .input-suffix {
        width: 245px;
      }

      .but {
        width: 50px;
      }
    }
  }

  .navs {
    margin: 0 auto;
    background: #2380d7;
    height: 60px;

    ul {
      overflow: hidden;
      padding: 0;
      margin: 0 auto;
      width: 1200px;
      border: 0;

      li {
        list-style: none;
        height: 60px;
        width: 200px;
        float: left;
        font-size: 16px;
      }

      .is-active {
        background: #1d72c1 !important;
        font-size: 16px;
      }
    }
  }
}

#search {
  padding-left: 18px;
}
@import "../../styles/common_responsive.less";
</style>
