import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//import axios from 'axios'
import  http from './utils/http'
import configDate from './utils/config.js'
import consoleLog from './utils/log.js'
import Moment from 'moment'
import echarts from 'echarts'
import loading from './utils/loading'
import 'font-awesome/css/font-awesome.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import vuetify from './plugins/vuetify';
// import Vuetify from 'vuetify/lib'
import dataV from '@jiaminghi/data-view'


import AmapVue from '@amap/amap-vue';
AmapVue.config.key = '253ba33f626f9cc8a14978e7ada1e788';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);
Vue.use(dataV)
import commonFunction from '../src/utils/paixun'
Vue.prototype.commonFunction = commonFunction ;


Moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');

Vue.filter('formatDate', function (value) {
    return Moment(value).format('yyyy-MM-DD')
})

Vue.filter('formatTime', function (value) {
    return Moment(value).format('yyyy-MM-DD HH:mm:ss')
})

Vue.filter('formatDatezh', function (value) {
    return Moment(value).format('yyyy年MM月DD 日')
})

Vue.filter('formatTimezh', function (value) {
    return Moment(value).format('yyyy年MM月DD日 HH:mm:ss')
})

Vue.filter('removeHtml', input => {
    return input && input.replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(”)/g, '\"')
        .replace(/“/g, '\"')
        .replace(/—/g, '-')
        .replace(/ /g, '')
        .replace(/>/g, '>')
        .replace(/</g, '<')
        .replace(/<[\w\s"':=\/]*/, '');
});

// Vue.component('vue-simple-verify', VueSimpleVerify)
Vue.prototype.$moment = Moment; //赋值使用
Vue.config.productionTip = false
Vue.prototype.$axios = http
Vue.prototype.$configDate = configDate
Vue.prototype.$log = consoleLog
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;

Vue.use(ElementUI);
Vue.use(vuetify)
Vue.prototype.$echarts = echarts

new Vue({
    router,
    store,
    loading,
    render: h => h(App),
    vuetify,

    methods: {
        info(message) {
            this.$message.info({
                message: message
            })
        },
        success(message) {
            this.$message({
                message: message,
                type: "success"
            })
        },
        warn(message) {
            this.$message({
                message: message,
                type: "warning"
            })
        },
        error(message) {
            this.$message.error({
                message: message
            })
        },
    }
}).$mount('#app')