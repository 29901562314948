/*
在文件的开始先定义一个对象
*/
const configDate = {
    //生产环境,
    url: 'https://www.gzjkqrc.com/api', //gs
    href: 'https://www.gzjkqrc.com/api',
    fileurl: 'https://www.gzjkqrc.com/api/file/object?path=',
    pdffileurl: 'https://www.gzjkqrc.com/api/file/object/preview?path=',

    // //开发环境,
    // url: 'http://192.168.2.133:8080/', //gs
    // href: 'http://192.168.2.133:8080/',
    // fileurl: 'http://192.168.2.133:8080//file/object?path=',
    // pdffileurl: 'http://192.168.2.133:8080//file/object/preview?path=', //gs,

    country: [
        {name: "中国"},
        {name: "埃及"},
        {name: "奥地利"},
        {name: "阿根廷"},
        {name: "澳洲"},
        {name: "阿富汗"},
        {name: "阿尔及利亚"},
        {name: "安哥拉"},
        {name: "阿森松岛"},
        {name: "埃塞俄比亚"},
        {name: "阿鲁巴 (荷兰王国)"},
        {name: "爱尔兰共和国"},
        {name: "阿尔巴尼亚"},
        {name: "爱沙尼亚"},
        {name: "安道尔"},
        {name: "阿曼"},
        {name: "阿拉伯联合酋长国"},
        {name: "阿塞拜疆"},
        {name: "安圭拉"},
        {name: "安提瓜和巴布达"},
        {name: "比利时"},
        {name: "波兰"},
        {name: "巴西"},
        {name: "巴基斯坦"},
        {name: "布基纳法索"},
        {name: "贝宁"},
        {name: "布隆迪"},
        {name: "博茨瓦纳"},
        {name: "冰岛"},
        {name: "保加利亚"},
        {name: "白俄罗斯"},
        {name: "波斯尼亚与赫塞哥维纳"},
        {name: "伯利兹"},
        {name: "巴拿马"},
        {name: "玻利维亚"},
        {name: "巴拉圭"},
        {name: "博内尔岛，圣尤斯特歇斯和"},
        {name: "巴布亚新几内亚"},
        {name: "巴勒斯坦"},
        {name: "巴林"},
        {name: "不丹"},
        {name: "巴哈马"},
        {name: "巴巴多斯"},
        {name: "百慕大 (英国)"},
        {name: "波多黎各 (美国)"},
        {name: "查德"},
        {name: "赤道几内亚"},
        {name: "丹麦"},
        {name: "德国"},
        {name: "多哥"},
        {name: "东帝汶"},
        {name: "东加"},
        {name: "多米尼克"},
        {name: "多米尼加共和国"},
        {name: "俄罗斯"},
        {name: "厄瓜多尔"},
        {name: "法国"},
        {name: "菲律宾"},
        {name: "佛得角"},
        {name: "法罗群岛 (丹麦)"},
        {name: "芬兰"},
        {name: "法属圭亚那 (法国)"},
        {name: "法属波利尼西亚 (法国)"},
        {name: "斐济"},
        {name: "古巴"},
        {name: "哥伦比亚"},
        {name: "冈比亚"},
        {name: "刚果共和国"},
        {name: "刚果民主共和国"},
        {name: "格陵兰 (丹麦)"},
        {name: "哥斯达黎加"},
        {name: "瓜德罗普岛"},
        {name: "圭亚那"},
        {name: "格林纳达"},
        {name: "关岛 (美国)"},
        {name: "哈萨克"},
        {name: "荷兰"},
        {name: "韩国"},
        {name: "黑山共和国"},
        {name: "洪都拉斯"},
        {name: "海地"},
        {name: "加拿大"},
        {name: "几内亚"},
        {name: "加纳"},
        {name: "加蓬"},
        {name: "几内亚比绍"},
        {name: "吉布提"},
        {name: "津巴布韦"},
        {name: "捷克"},
        {name: "基里巴斯"},
        {name: "柬埔寨"},
        {name: "吉尔吉斯"},
        {name: "科特迪瓦"},
        {name: "喀麦隆"},
        {name: "肯尼亚"},
        {name: "科摩罗"},
        {name: "克罗地亚"},
        {name: "库拉索 (荷兰王国)"},
        {name: "库克群岛 (新西兰)"},
        {name: "科威特"},
        {name: "卡达"},
        {name: "开曼群岛 (英国)"},
        {name: "罗马尼亚"},
        {name: "利比亚"},
        {name: "利比里亚"},
        {name: "卢旺达"},
        {name: "留尼汪 (法国)"},
        {name: "莱索托"},
        {name: "卢森堡"},
        {name: "立陶宛"},
        {name: "拉脱维亚"},
        {name: "列支敦士登"},
        {name: "老挝"},
        {name: "黎巴嫩"},
        {name: "美国"},
        {name: "秘鲁"},
        {name: "墨西哥"},
        {name: "马来西亚"},
        {name: "缅甸"},
        {name: "摩洛哥"},
        {name: "毛里塔尼亚"},
        {name: "马里共和国"},
        {name: "毛里求斯"},
        {name: "莫桑比克"},
        {name: "马达加斯加"},
        {name: "马拉维"},
        {name: "马约特"},
        {name: "马其顿"},
        {name: "摩尔多瓦"},
        {name: "马耳他"},
        {name: "摩纳哥"},
        {name: "马提尼克 (法国)"},
        {name: "孟加拉国"},
        {name: "马尔代夫"},
        {name: "蒙古国"},
        {name: "美属维尔京群岛 (美国)"},
        {name: "蒙塞拉特岛 (英国)"},
        {name: "美属萨摩亚 (美国)"},
        {name: "南非"},
        {name: "挪威"},
        {name: "南苏丹"},
        {name: "尼日"},
        {name: "尼日利亚"},
        {name: "纳米比亚"},
        {name: "尼加拉瓜"},
        {name: "尼泊尔"},
        {name: "葡萄牙"},
        {name: "帕劳"},
        {name: "乔治亚"},
        {name: "瑞士"},
        {name: "瑞典"},
        {name: "日本"},
        {name: "斯里兰卡"},
        {name: "塞内加尔"},
        {name: "狮子山共和国"},
        {name: "圣多美普林西比"},
        {name: "塞舌尔"},
        {name: "苏丹"},
        {name: "索马里"},
        {name: "斯威士兰"},
        {name: "塞浦路斯"},
        {name: "圣马力诺"},
        {name: "塞尔维亚共和国"},
        {name: "斯洛文尼亚"},
        {name: "斯洛伐克"},
        {name: "萨尔瓦多"},
        {name: "圣皮耶与密克隆群岛 (法国)"},
        {name: "圣马丁岛(荷兰部分)"},
        {name: "苏里南"},
        {name: "所罗门群岛"},
        {name: "萨摩亚"},
        {name: "沙特阿拉伯"},
        {name: "圣卢西亚"},
        {name: "圣文森及格林纳丁"},
        {name: "圣克里斯多福与尼维斯"},
        {name: "泰国"},
        {name: "土耳其"},
        {name: "突尼斯"},
        {name: "坦桑尼亚"},
        {name: "塔吉克"},
        {name: "土库曼"},
        {name: "土克凯可群岛 (英国)"},
        {name: "特立尼达和多巴哥"},
        {name: "委内瑞拉"},
        {name: "乌干达"},
        {name: "乌克兰"},
        {name: "危地马拉"},
        {name: "乌拉圭"},
        {name: "文莱"},
        {name: "瓦努阿图"},
        {name: "乌兹别克"},
        {name: "希腊"},
        {name: "西班牙"},
        {name: "匈牙利"},
        {name: "新西兰"},
        {name: "新加坡"},
        {name: "新喀里多尼亚 (法国)"},
        {name: "叙利亚"},
        {name: "意大利"},
        {name: "英国"},
        {name: "印度尼西亚"},
        {name: "越南"},
        {name: "印度"},
        {name: "伊朗"},
        {name: "亚美尼亚"},
        {name: "约旦"},
        {name: "伊拉克"},
        {name: "也门"},
        {name: "以色列"},
        {name: "英属维尔京群岛 (英国)"},
        {name: "牙买加"},
        {name: "智利"},
        {name: "中非共和国"},
        {name: "赞比亚"},
        {name: "直布罗陀 (英国)"},
    ],
    nation: [
        {nation: "汉族", label: "汉族"},
        {nation: "蒙古族", label: "蒙古族"},
        {nation: "回族", label: "回族"},
        {nation: "藏族", label: "藏族"},
        {nation: "维吾尔族", label: "维吾尔族"},
        {nation: "苗族", label: "苗族"},
        {nation: "彝族", label: "彝族"},
        {nation: "壮族", label: "壮族"},
        {nation: "布依族", label: "布依族"},
        {nation: "朝鲜族", label: "朝鲜族"},
        {nation: "满族", label: "满族"},
        {nation: "侗族", label: "侗族"},
        {nation: "瑶族", label: "瑶族"},
        {nation: "白族", label: "白族"},
        {nation: "土家族", label: "土家族"},
        {nation: "哈尼族", label: "哈尼族"},
        {nation: "哈萨克族", label: "哈萨克族"},
        {nation: "傣族", label: "傣族"},
        {nation: "黎族", label: "黎族"},
        {nation: "僳僳族", label: "僳僳族"},
        {nation: "佤族", label: "佤族"},
        {nation: "畲族", label: "畲族"},
        {nation: "高山族", label: "高山族"},
        {nation: "拉祜族", label: "拉祜族"},
        {nation: "水族", label: "水族"},
        {nation: "东乡族", label: "东乡族"},
        {nation: "纳西族", label: "纳西族"},
        {nation: "景颇族", label: "景颇族"},
        {nation: "柯尔克孜族", label: "柯尔克孜族"},
        {nation: "土族", label: "土族"},
        {nation: "达斡尔族", label: "达斡尔族"},
        {nation: "仫佬族", label: "仫佬族"},
        {nation: "羌族", label: "羌族"},
        {nation: "布朗族", label: "布朗族"},
        {nation: "撒拉族", label: "撒拉族"},
        {nation: "毛南族", label: "毛南族"},
        {nation: "仡佬族", label: "仡佬族"},
        {nation: "锡伯族", label: "锡伯族"},
        {nation: "阿昌族", label: "阿昌族"},
        {nation: "普米族", label: "普米族"},
        {nation: "塔吉克族", label: "塔吉克族"},
        {nation: "怒族", label: "怒族"},
        {nation: "乌孜别克族", label: "乌孜别克族"},
        {nation: "俄罗斯族", label: "俄罗斯族"},
        {nation: "鄂温克族", label: "鄂温克族"},
        {nation: "德昂族", label: "德昂族"},
        {nation: "保安族", label: "保安族"},
        {nation: "裕固族", label: "裕固族"},
        {nation: "京族", label: "京族"},
        {nation: "塔塔尔族", label: "塔塔尔族"},
        {nation: "独龙族", label: "独龙族"},
        {nation: "鄂伦春族", label: "鄂伦春族"},
        {nation: "赫哲族", label: "赫哲族"},
        {nation: "门巴族", label: "门巴族"},
        {nation: "珞巴族", label: "珞巴族"},
        {nation: "基诺族", label: "基诺族"}
    ],
    //学历
    education: [
        {academic: "小学", label: "小学"},
        {academic: "高中", label: "高中"},
        {academic: "大专", label: "大专"},
        {academic: "本科", label: "本科"},
        {academic: "硕士研究生", label: "硕士研究生"},
        {academic: "博士研究生", label: "博士研究生"}
    ],
    GanZhou: [
        {block: '章贡区', label: '章贡区'},
        {block: '开发区', label: '开发区'},
        {block: '蓉江新区', label: '蓉江新区'},
        {block: '南康区', label: '南康区'},
        {block: '赣县区', label: '赣县区'}
    ],
    renCai: [
        {
            label: 'A+类(产业领军人才)',
            value: '掌握国内外先进技术，拥有独立自主知识产权，项目成果具有较高科技含量，来我市创办、领办高新企业，经过评审认定对我市首位、主导及特色产业具有重大影响的各类人才。'
        }, {
            label: 'A类(国内外顶尖人才)',
            value: '1.诺贝尔奖获得者； 2.国家最高科学技术奖获得者； 3.中国科学院院士； 4.中国工程院院士； 5.中国社会科学院学部委员、荣誉学部委员； 6.相当于上述层次的人才。'
        }, {
            label: 'B类(国家级高层次人才)',
            value: '1.国家有突出贡献中青年专家；\n' +
                '2.国家“QRJH”人选、国家“万人计划”人选；\n' +
                '3.百千万人才工程国家级人选；\n' +
                '4.国务院批准的享受政府特殊津贴的专家；\n' +
                '5.中科院“百人计划”A类人选；\n' +
                '6.全国宣传文化系统“四个一批”人才；\n' +
                '7.“长江学者奖励计划”教授；\n' +
                '8.国家杰出(优)青年科学基金获得者、中国青年科技奖获得者(前3位完成人)；\n' +
                '9.国家自然科学奖、国家技术发明奖、国家科学技术进步奖一等奖获得者(前3位完成人)；\n' +
                '10.国家卫健委(卫生部)有突出贡献的中青年专家；\n' +
                '11.国家级教学名师；全国基础教育教学成果奖(前3位完成人)；\n' +
                '12.国家级教学成果奖特等奖获得者(前3位完成人)；\n' +
                '13.中华技能大奖获得者、国家级技能大师工作室领办人；\n' +
                '14.相当于上述层次的人才。'
        }, {
            label: 'C类(省级高层次人才)',
            value: '1.省“双千计划”人选(若团队，特指团队带头)；\n' +
                '2.省有突出贡献中青年专家；\n' +
                '3.“赣鄱英才555工程”人选；\n' +
                '4.百千万人才工程省级人选；\n' +
                '5.省政府批准的享受政府特殊津贴的专家；\n' +
                '6.国家自然科学奖、国家技术发明奖、国家科学技术进步奖二等奖获得者(前3位完成人)；\n' +
                '7.省自然科学奖、技术发明奖、科技进步奖一等奖获得者(前3位完成人)；\n' +
                '8.省宣传文化系统“四个一批”人才；\n' +
                '9.省“井冈学者”特聘教授；\n' +
                '10.省自然科学基金计划(杰)青年基金项目获得者人选；\n' +
                '11.国家级教学成果奖一等奖、二等奖获得者(前3位完成人)；\n' +
                '12.省政府授予的赣鄱工匠；\n' +
                '13.省级技能大师工作室领办人；\n' +
                '14.省卫生健康突出贡献中青年专家；\n' +
                '15.世界技能大赛金牌获得者；\n' +
                '16.相当于上述层次的人才。'
        }, {
            label: 'D类(市级高层次人才)',
            value: '1.“苏区之光”人才计划人选(若团队，特指团队带头)；\n' +
                '2.赣州市突出贡献人才；\n' +
                '3.赣州市政府特殊津贴专家；\n' +
                '4.省自然科学奖、技术发明奖、科技进步奖(二等)获得者(前3位完成人)；\n' +
                '5.赣州市青年科技奖获得者；\n' +
                '6.省特级教师；\n' +
                '7.省政府授予的能工巧匠；\n' +
                '8.省振兴杯职业技能竞赛第一名(职工组、教师组)；\n' +
                '9.市级技能大师工作室领办人、市级首席技师；\n' +
                '10.具有正高级专业技术职务任职资格的专业技术人才；\n' +
                '11.博士学位获得者；\n' +
                '12.相当于上述层次的人才。'
        }, {
            label: 'E类(行业急需紧缺人才)',
            value: '1.各行业具有副高级专业技术职务任职资格的专业技术人才；\n' +
                '2.具有高级技师职业资格证书的技能人才；\n' +
                '3.全日制硕士研究生；\n' +
                '4.符合市组织、人社部门发布的急需紧缺人才目录的各类人才；\n' +
                '5.相当于上述层次的人才。'
        }, {
            label: 'F类(行业英才)',
            value: '1.各行业具有中级专业技术职务任职资格的专业技术人才；\n' +
                '2.全市单位、企业正式录用、聘用的全日制大学本科学历人才(含教育部认可的境外高等院校毕业的归国留学人)。'
        }, {
            label: 'G类(产业能手)',
            value: '经过技术培训，并连续(非累)在我市首位、主导及特色产业企业工作满3年及以上的产业工人。其中，连续满3年可获得初级工匠称号，连续满6年可获得中级工匠称号，连续满9年可获得高级工匠称号。'
        },
    ],
    /*
      引进地方
    */
    introduce: [
        {value: "省内", number: "introduce1"},
        {value: "省外", number: "introduce2"},
        {value: "国外", number: "introduce3"},
    ],
    /*
      从事专业领域
     */
    professionalField: [
        {value: "有色金属"},
        {value: "电子信息"},
        {value: "航空"},
        {value: "钢铁"},
        {value: "新能源"},
        {value: "数字经济"},
        {value: "生物医药"},
        {value: "商贸物流"},
        {value: "信息安全"},
        {value: "虚拟现实"},
        {value: "汽车"},
        {value: "房地产建筑"},
        {value: "绿色食品"},
        {value: "文化和旅游"},
        {value: "纺织服装"},
        {value: "现代家居"},
        {value: "其他产业"},
        {value: "自然科学"},
        {value: "社会科学"},
        {value: "中小学教育"},
        {value: "人文社科"},
        {value: "医疗卫生"},
        {value: "宣传文化新闻出版"},
        {value: "其他领域"}
    ],
    /*
     职称类别
    */
    optionsCertificate: [
        {value: "无", label: "无"},
        {value: "初级工", label: "初级工"},
        {value: "正高", label: "正高"},
        {value: "副高", label: "副高"},
        {value: "中级职称", label: "中级职称"},
        {value: "初级职称", label: "初级职称"},
        {value: "高级技师", label: "高级技师"},
        {value: "技师", label: "技师"},
        {value: "高级工", label: "高级工"},
        {value: "中级工", label: "中级工"},
    ],
    /*
    人才类型
     */
    typesTalents: [
        {name: "A+类（产业领军人才）"},
        {name: "A类（国内外顶尖人才 ）"},
        {name: "B类（国家级高层次人才）"},
        {name: "C类（省级高层次人才 ）"},
        {name: "D类（市级高层次人才）"},
        {name: "E类（行业急需紧缺人才）"},
        {name: "F类（行业英才）"},
        {name: "G类（产业能手）"},
    ],
    /*
    所获荣誉级别
    */
    awardLevel: [
        {value: "国家级", number: 1},
        {value: "省级", number: 2},
        {value: "市级", number: 3},
        {value: "其他 ", number: 4}
    ],
    //图表颜色
    colorList: [
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
        "#6acd82",
        "#dc916b",
        "#837af6",
        "#75afee",
        "#eccc76",
      ],
//地级市
   provinces: [
    {provinceName:"河北省",citys:[
      {label:"石家庄市",value:"石家庄市"},
      {label:"唐山市",value:"唐山市"},
      {label:"秦皇岛市",value:"秦皇岛市"},
      {label:"邯郸市",value:"邯郸市"},
      {label:"邢台市",value:"邢台市"},
      {label:"保定市",value:"保定市"},
      {label:"张家口市",value:"张家口市"},
      {label:"承德市",value:"承德市"},
      {label:"沧州市",value:"沧州市"},
      {label:"廊坊市",value:"廊坊市"},
      {label:"衡水市",value:"衡水市"}
    ]},
    {provinceName:"山西省",citys:[
      {label:"太原市",value:"太原市"},
      {label:"大同市",value:"大同市"},
      {label:"阳泉市",value:"阳泉市"},
      {label:"长治市",value:"长治市"},
      {label:"晋城市",value:"晋城市"},
      {label:"朔州市",value:"朔州市"},
      {label:"晋中市",value:"晋中市"},
      {label:"运城市",value:"运城市"},
      {label:"忻州市",value:"忻州市"},
      {label:"临汾市",value:"临汾市"},
      {label:"吕梁市",value:"吕梁市"},
  
    ]},
    {provinceName:"内蒙古自治区",citys:[
      {label:"呼和浩特市",value:"呼和浩特市"},
      {label:"包头市",value:"包头市"},
      {label:"乌海市",value:"乌海市"},
      {label:"赤峰市",value:"赤峰市"},
      {label:"通辽市",value:"通辽市"},
      {label:"鄂尔多斯市",value:"鄂尔多斯市"},
      {label:"呼伦贝尔市",value:"呼伦贝尔市"},
      {label:"巴彦淖尔市",value:"巴彦淖尔市"},
      {label:"乌兰察布市",value:"乌兰察布市"},
    ]},
    {provinceName:"辽宁省",citys:[
      {label: '沈阳市', value: '沈阳市'},
      {label: '大连市', value: '大连市'},
      {label: '鞍山市', value: '鞍山市'},
      {label: '抚顺市', value: '抚顺市'},
      {label: '本溪市', value: '本溪市'},
      {label: '丹东市', value: '丹东市'},
      {label: '锦州市', value: '锦州市'},
      {label: '营口市', value: '营口市'},
      {label: '阜新市', value: '阜新市'},
      {label: '辽阳市', value: '辽阳市'},
      {label: '盘锦市', value: '盘锦市'},
      {label: '铁岭市', value: '铁岭市'},
      {label: '朝阳市', value: '朝阳市'},
      {label: '葫芦岛市', value: '葫芦岛市'}
  
    ]},
    {provinceName:"吉林省",citys:[
      {label:"长春市",value:"长春市"},
      {label:"吉林市",value:"吉林市"},
      {label:"四平市",value:"四平市"},
      {label:"辽源市",value:"辽源市"},
      {label:"通化市",value:"通化市"},
      {label:"白山市",value:"白山市"},
      {label:"松原市",value:"松原市"},
      {label:"白城市",value:"白城市"},
    ]},
    {provinceName:"黑龙江省",citys:[
      {label:"哈尔滨市",value:"哈尔滨市"},
      {label:"齐齐哈尔市",value:"齐齐哈尔市"},
      {label:"鸡西市",value:"鸡西市"},
      {label:"鹤岗市",value:"鹤岗市"},
      {label:"双鸭山市",value:"双鸭山市"},
      {label:"大庆市",value:"大庆市"},
      {label:"伊春市",value:"伊春市"},
      {label:"佳木斯市",value:"佳木斯市"},
      {label:"七台河市",value:"七台河市"},
      {label:"牡丹江市",value:"牡丹江市"},
      {label:"黑河市",value:"黑河市"},
      {label:"绥化市",value:"绥化市"},
    ]},
    {provinceName:"江苏省",citys:[
      {label:"南京市",value:"南京市"},
      {label:"无锡市",value:"无锡市"},
      {label:"徐州市",value:"徐州市"},
      {label:"常州市",value:"常州市"},
      {label:"苏州市",value:"苏州市"},
      {label:"南通市",value:"南通市"},
      {label:"连云港市",value:"连云港市"},
      {label:"淮安市",value:"淮安市"},
      {label:"盐城市",value:"盐城市"},
      {label:"扬州市",value:"扬州市"},
      {label:"镇江市",value:"镇江市"},
      {label:"泰州市",value:"泰州市"},
      {label:"宿迁市",value:"宿迁市"},
    ]},
    {provinceName:"浙江省",citys:[
      {label:"杭州市",value:"杭州市"},
      {label:"宁波市",value:"宁波市"},
      {label:"温州市",value:"温州市"},
      {label:"嘉兴市",value:"嘉兴市"},
      {label:"湖州市",value:"湖州市"},
      {label:"绍兴市",value:"绍兴市"},
      {label:"金华市",value:"金华市"},
      {label:"衢州市",value:"衢州市"},
      {label:"舟山市",value:"舟山市"},
      {label:"台州市",value:"台州市"},
      {label:"丽水市",value:"丽水市"},
    ]},
    {provinceName:"安徽省",citys:[
      {label:" 合肥市",value:" 合肥市"},
      {label:"芜湖市",value:"芜湖市"},
      {label:"蚌埠市",value:"蚌埠市"},
      {label:"淮南市",value:"淮南市"},
      {label:"马鞍山市",value:"马鞍山市"},
      {label:"淮北市",value:"淮北市"},
      {label:"铜陵市",value:"铜陵市"},
      {label:"安庆市",value:"安庆市"},
      {label:"黄山市",value:"黄山市"},
      {label:"阜阳市",value:"阜阳市"},
      {label:"宿州市",value:"宿州市"},
      {label:"滁州市",value:"滁州市"},
      {label:"六安市",value:"六安市"},
      {label:"宣城市",value:"宣城市"},
      {label:"池州市",value:"池州市"},
      {label:"亳州市",value:"亳州市"},
    ]},
    {provinceName:"福建省",citys:[
      {label:"福州市",value:"福州市"},
      {label:"厦门市",value:"厦门市"},
      {label:"莆田市",value:"莆田市"},
      {label:"三明市",value:"三明市"},
      {label:"泉州市",value:"泉州市"},
      {label:"漳州市",value:"漳州市"},
      {label:"南平市",value:"南平市"},
      {label:"龙岩市",value:"龙岩市"},
      {label:"宁德市",value:"宁德市"},
    ]},
    {provinceName:"江西省",citys:[
      {label:"南昌市",value:"南昌市"},
      {label:"景德镇市",value:"景德镇市"},
      {label:"萍乡市",value:"萍乡市"},
      {label:"九江市",value:"九江市"},
      {label:"抚州市",value:"抚州市"},
      {label:"鹰潭市",value:"鹰潭市"},
      {label:"赣州市",value:"赣州市"},
      {label:"吉安市",value:"吉安市"},
      {label:"宜春市",value:"宜春市"},
      {label:"新余市",value:"新余市"},
      {label:"上饶市",value:"上饶市"},
    ]},
    {provinceName:"山东省",citys:[
      {label:"济南市",value:"济南市"},
      {label:"青岛市",value:"青岛市"},
      {label:"淄博市",value:"淄博市"},
      {label:"枣庄市",value:"枣庄市"},
      {label:"东营市",value:"东营市"},
      {label:"烟台市",value:"烟台市"},
      {label:"潍坊市",value:"潍坊市"},
      {label:"济宁市",value:"济宁市"},
      {label:"泰安市",value:"泰安市"},
      {label:"威海市",value:"威海市"},
      {label:"日照市",value:"日照市"},
      {label:"临沂市",value:"临沂市"},
      {label:"德州市",value:"德州市"},
      {label:"聊城市",value:"聊城市"},
      {label:"滨州市",value:"滨州市"},
      {label:"菏泽市",value:"菏泽市"},
    ]},
    {provinceName:"河南省",citys:[
      {label:"郑州市",value:"郑州市"},
      {label:"开封市",value:"开封市"},
      {label:"洛阳市",value:"洛阳市"},
      {label:"平顶山市",value:"平顶山市"},
      {label:"安阳市",value:"安阳市"},
      {label:"鹤壁市",value:"鹤壁市"},
      {label:"新乡市",value:"新乡市"},
      {label:"焦作市",value:"焦作市"},
      {label:"濮阳市",value:"濮阳市"},
      {label:"许昌市",value:"许昌市"},
      {label:"漯河市",value:"漯河市"},
      {label:"三门峡市",value:"三门峡市"},
      {label:"南阳市",value:"南阳市"},
      {label:"商丘市",value:"商丘市"},
      {label:"信阳市",value:"信阳市"},
      {label:"周口市",value:"周口市"},
      {label:"驻马店市",value:"驻马店市"},
    ]},
    {provinceName:"湖北省",citys:[
      {label:"武汉市",value:"武汉市"},
      {label:"黄石市",value:"黄石市"},
      {label:"十堰市",value:"十堰市"},
      {label:"宜昌市",value:"宜昌市"},
      {label:"襄阳市",value:"襄阳市"},
      {label:"鄂州市",value:"鄂州市"},
      {label:"荆门市",value:"荆门市"},
      {label:"孝感市",value:"孝感市"},
      {label:"荆州市",value:"荆州市"},
      {label:"黄冈市",value:"黄冈市"},
      {label:"咸宁市",value:"咸宁市"},
      {label:"随州市",value:"随州市"},
    ]},
    {provinceName:"湖南省",citys:[
      {label:"长沙市",value:"长沙市"},
      {label:"株洲市",value:"株洲市"},
      {label:"湘潭市",value:"湘潭市"},
      {label:"衡阳市",value:"衡阳市"},
      {label:"邵阳市",value:"邵阳市"},
      {label:"岳阳市",value:"岳阳市"},
      {label:"常德市",value:"常德市"},
      {label:"张家界市",value:"张家界市"},
      {label:"益阳市",value:"益阳市"},
      {label:"郴州市",value:"郴州市"},
      {label:"永州市",value:"永州市"},
      {label:"怀化市",value:"怀化市"},
      {label:"娄底市",value:"娄底市"},
    ]},
    {provinceName:"广东省",citys:[
      {label:"广州市",value:"广州市"},
      {label:"韶关市",value:"韶关市"},
      {label:"深圳市",value:"深圳市"},
      {label:"珠海市",value:"珠海市"},
      {label:"汕头市",value:"汕头市"},
      {label:"佛山市",value:"佛山市"},
      {label:"江门市",value:"江门市"},
      {label:"湛江市",value:"湛江市"},
      {label:"茂名市",value:"茂名市"},
      {label:"肇庆市",value:"肇庆市"},
      {label:"惠州市",value:"惠州市"},
      {label:"梅州市",value:"梅州市"},
      {label:"汕尾市",value:"汕尾市"},
      {label:"河源市",value:"河源市"},
      {label:"阳江市",value:"阳江市"},
      {label:"清远市",value:"清远市"},
      {label:"东莞市",value:"东莞市"},
      {label:"中山市",value:"中山市"},
      {label:"潮州市",value:"潮州市"},
      {label:"揭阳市",value:"揭阳市"},
      {label:"云浮市",value:"云浮市"},
    ]},
    {provinceName:"广西壮族自治区",citys:[
      {label:"南宁市",value:"南宁市"},
      {label:"柳州市",value:"柳州市"},
      {label:"桂林市",value:"桂林市"},
      {label:"梧州市",value:"梧州市"},
      {label:"北海市",value:"北海市"},
      {label:"防城港市",value:"防城港市"},
      {label:"钦州市",value:"钦州市"},
      {label:"贵港市",value:"贵港市"},
      {label:"玉林市",value:"玉林市"},
      {label:"百色市",value:"百色市"},
      {label:"贺州市",value:"贺州市"},
      {label:"河池市",value:"河池市"},
      {label:"来宾市",value:"来宾市"},
      {label:"崇左市",value:"崇左市"},
    ]},
    {provinceName:"海南省",citys:[
      {label:"海口市",value:"海口市"},
      {label:"三亚市",value:"三亚市"},
      {label:"三沙市",value:"三沙市"},
      {label:"儋州市",value:"儋州市"},
    ]},
    {provinceName:"四川省",citys:[
      {label:"成都市",value:"成都市"},
      {label:"自贡市",value:"自贡市"},
      {label:"攀枝花市",value:"攀枝花市"},
      {label:"泸州市",value:"泸州市"},
      {label:"德阳市",value:"德阳市"},
      {label:"绵阳市",value:"绵阳市"},
      {label:"广元市",value:"广元市"},
      {label:"遂宁市",value:"遂宁市"},
      {label:"内江市",value:"内江市"},
      {label:"乐山市",value:"乐山市"},
      {label:"南充市",value:"南充市"},
      {label:"眉山市",value:"眉山市"},
      {label:"宜宾市",value:"宜宾市"},
      {label:"广安市",value:"广安市"},
      {label:"达州市",value:"达州市"},
      {label:"雅安市",value:"雅安市"},
      {label:"巴中市",value:"巴中市"},
      {label:"资阳市",value:"资阳市"},
    ]},
    {provinceName:"贵州省",citys:[
      {label:"贵阳市",value:"贵阳市"},
      {label:"六盘水市",value:"六盘水市"},
      {label:"遵义市",value:"遵义市"},
      {label:"安顺市",value:"安顺市"},
      {label:"毕节市",value:"毕节市"},
      {label:"铜仁市",value:"铜仁市"},
    ]},
    {provinceName:"云南省",citys:[
      {label:"昆明市",value:"昆明市"},
      {label:"曲靖市",value:"曲靖市"},
      {label:"玉溪市",value:"玉溪市"},
      {label:"保山市",value:"保山市"},
      {label:"昭通市",value:"昭通市"},
      {label:"丽江市",value:"丽江市"},
      {label:"普洱市",value:"普洱市"},
      {label:"临沧市",value:"临沧市"},
    ]},
    {provinceName:"西藏自治区",citys:[
      {label:"拉萨市",value:"拉萨市"},
      {label:"日喀则市",value:"日喀则市"},
      {label:"昌都市",value:"昌都市"},
      {label:"林芝市",value:"林芝市"},
      {label:"山南市",value:"山南市"},
      {label:"那曲市",value:"那曲市"},
    ]},
    {provinceName:"陕西省",citys:[
      {label:"西安市",value:"西安市"},
      {label:"铜川市",value:"铜川市"},
      {label:"宝鸡市",value:"宝鸡市"},
      {label:"咸阳市",value:"咸阳市"},
      {label:"渭南市",value:"渭南市"},
      {label:"延安市",value:"延安市"},
      {label:"汉中市",value:"汉中市"},
      {label:"榆林市",value:"榆林市"},
      {label:"安康市",value:"安康市"},
      {label:"商洛市",value:"商洛市"},
    ]},
    {provinceName:"甘肃省",citys:[
      {label:"兰州市",value:"兰州市"},
      {label:"嘉峪关市",value:"嘉峪关市"},
      {label:"金昌市",value:"金昌市"},
      {label:"白银市",value:"白银市"},
      {label:"天水市",value:"天水市"},
      {label:"武威市",value:"武威市"},
      {label:"张掖市",value:"张掖市"},
      {label:"平凉市",value:"平凉市"},
      {label:"酒泉市",value:"酒泉市"},
      {label:"庆阳市",value:"庆阳市"},
      {label:"定西市",value:"定西市"},
      {label:"陇南市",value:"陇南市"},
    ]},
    {provinceName:"青海省",citys:[
      {label:"西宁市",value:"西宁市"},
      {label:"海东市",value:"海东市"},
    ]},
    {provinceName:"宁夏回族自治区",citys:[
      {label:"银川市",value:"银川市"},
      {label:"石嘴山市",value:"石嘴山市"},
      {label:"吴忠市",value:"吴忠市"},
      {label:"固原市",value:"固原市"},
      {label:"中卫市",value:"中卫市"},
    ]},
    {provinceName:"新疆维吾尔自治区",citys:[
      {label:"乌鲁木齐市",value:"乌鲁木齐市"},
      {label:"克拉玛依市",value:"克拉玛依市"},
      {label:"吐鲁番市",value:"吐鲁番市"},
      {label:"哈密市",value:"哈密市"},
    ]},
  ],
  illustrate:`
  <div class="content" style="min-height: 860px">
	<p style="text-indent: 0em; text-align: center;">
		<span style="font-size: 18px;">
			赣州市人才分类目录
		</span>
	</p>
	<p style="text-indent: 0em; text-align: center;">
		<span style="font-size: 18px;">
			（2021年修订版）
		</span>
	</p>
	<p style="text-indent: 2em;">
		&nbsp;
	</p>
	<p style="text-indent: 2em;">
		赣州市人才分类目录分为8个层次，分别是：
	</p>
	<p style="text-indent: 2em;">
		<strong>
			A+类（产业领军人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		掌握国内外先进技术，拥有独立自主知识产权，项目成果具有较高科技含量，来我市创办、领办高新企业，经过评审认定对我市首位、主导及特色产业具有重大影响的各类人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			A类（国内外顶尖人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．诺贝尔奖获得者，国家最高科学技术奖获得者；
	</p>
	<p style="text-indent: 2em;">
		2．中国科学院院士，中国工程院院士，中国社会科学院学部委员、荣誉学部委员；
	</p>
	<p style="text-indent: 2em;">
		3．美国、日本、德国、法国、英国、意大利、加拿大、瑞典、丹麦、挪威、芬兰、比利时、瑞士、奥地利、荷兰、澳大利亚、新西兰、俄罗斯、新加坡、韩国、西班牙、印度、乌克兰、以色列国家最高学术权威机构会员(一般为member或fellow，统一翻译为“院士”，见中国科学院国际合作局公布名单)；
	</p>
	<p style="text-indent: 2em;">
		4．相当于上述层次的人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			B类（国家级高层次人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．国家“**计划”人选、国家“**计划”人选，国家新的重大人才工程特定项目人选，中科院“百人计划”A类人选；
	</p>
	<p style="text-indent: 2em;">
		2．国家有突出贡献中青年专家，国务院批准的享受政府特殊津贴的专家，百千万 人才工程国家级人选；
	</p>
	<p style="text-indent: 2em;">
		3．国家自然科学奖、国家技术发明奖、国家科学技术进步奖一等奖获得者（前3位完成人），国家杰出（优秀）青年科学基金获得者，中国青年科技奖获得者（前3位完成人），“国防卓越青年科学基金”获得者；
	</p>
	<p style="text-indent: 2em;">
		4．全国宣传思想文化系统 “四个一批”人才和青年英才、国家级非物质文化遗产传承人、中国工艺美术大师；
	</p>
	<p style="text-indent: 2em;">
		5．“长江学者奖励计划”教授，国家级教学名师，全国基础教育教学成果奖获得者（前3位完成人），国家级教学成果奖特等奖获得者（前3位完成人）；
	</p>
	<p style="text-indent: 2em;">
		6．国家卫健委(卫生部)有突出贡献的中青年专家；
	</p>
	<p style="text-indent: 2em;">
		7．全国杰出专业技术人才、中华技能大奖获得者、国家级技能大师工作室领办人；
	</p>
	<p style="text-indent: 2em;">
		8．相当于上述层次的人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			C类（省级高层次人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．省“双 千计划”人选（若团队，特指团队带头人），“赣鄱英才555工程”人选；
	</p>
	<p style="text-indent: 2em;">
		2．省有突出贡献中青年专家，百千万 人才工程省级人选，省政府批准的享受政府特殊津贴的专家；
	</p>
	<p style="text-indent: 2em;">
		3．国家自然科学奖、国家技术发明奖、国家科学技术进步奖二等奖获得者(前3位完成人)，省自然科学奖、技术发明奖、科技进步奖一等奖获得者(前3位完成人)，江西省主要学科学术和技术带头人，省自然科学基金计划（杰出）青年基金项目获得者人选；
	</p>
	<p style="text-indent: 2em;">
		4．全省宣传思想文化系统 “四个一批”人才和青年英才、省级非物质文化遗产传承人；
	</p>
	<p style="text-indent: 2em;">
		5．省“井冈学者”特聘教授，国家级教学成果奖一等奖、二等奖获得者(前3位完成人)；
	</p>
	<p style="text-indent: 2em;">
		6．省卫生健康突出贡献中青年专家；
	</p>
	<p style="text-indent: 2em;">
		7．省政府授予的赣鄱工匠，省级技能大师工作室领办人、省级首席技师，世界技能大赛金银铜牌获得者；
	</p>
	<p style="text-indent: 2em;">
		8．近5年，国家级重点实验室、工程（技术）研究中心、工程实验室学术技术带头人；在国内外担任重大科技项目的首席科学家、重大工程项目的首席工程技术专家和管理专家；国家科技重大专项专家组组长、副组长，项目组长、且项目通过验收；国家社会科学基金重大项目首席专家，且项目已完成；获得国家自然科学基金“重大项目基金”资助的项目主持人，且项目已完成；
	</p>
	<p style="text-indent: 2em;">
		9．近5年，在Nature或Science上以第一作者或第一通讯作者发表论文者；
	</p>
	<p style="text-indent: 2em;">
		10．近5年，世界500强企业总部、二级公司或地区总部总经理和中国500强企业主要经营管理人才；管理资产超过300亿元的金融投资、资产管理总部主要负责人；
	</p>
	<p style="text-indent: 2em;">
		11．相当于上述层次的人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			D类（市级高层次人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．“苏区之光”人才计划人选（若团队，特指团队带头人）；
	</p>
	<p style="text-indent: 2em;">
		2．具有正高级专业技术职务任职资格的专业技术人才；
	</p>
	<p style="text-indent: 2em;">
		3．博士学位获得者；
	</p>
	<p style="text-indent: 2em;">
		4．赣州市突出贡献人才，赣州市政府特殊津贴专家；
	</p>
	<p style="text-indent: 2em;">
		5．国家自然科学奖、国家技术发明奖、国家科学技术进步奖三等奖获得者(前3位完成人)，省自然科学奖、技术发明奖、科技进步奖（二等奖）获得者（前3位完成人），赣州市青年科技奖获得者，赣州市十大科技创新人物（若团队，特指团队带头人；不含提名奖获得者）；
	</p>
	<p style="text-indent: 2em;">
		6．省青年井冈学者，省特级教师；
	</p>
	<p style="text-indent: 2em;">
		7．中华人民共和国职业技能大赛金银铜牌获得者（职工组、教师组、学生组），省政府授予的能工巧匠，省职业技能大赛一等奖(职工组、教师组)，市级技能大师工作室领办人、市政府授予的赣州工匠、市级首席技师；
	</p>
	<p style="text-indent: 2em;">
		8．近5年，世界500强地区总部的技术研发和管理团队核心成员；中国制造业企业500强和中国民营500强企业主要经营管理人才；世界500强金融企业中担任中高级以上职务的金融专家；
	</p>
	<p style="text-indent: 2em;">
		9．相当于上述层次的人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			E类（行业急需紧缺人才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．各行业具有副高级专业技术职务任职资格的专业技术人才，具有技师及以上职业资格证书（职业技能等级证书）的技能人才；
	</p>
	<p style="text-indent: 2em;">
		2．全日制硕士研究生；
	</p>
	<p style="text-indent: 2em;">
		3．省自然科学奖、技术发明奖、科技进步奖（三等奖）获得者（前3位完成人），省级社会科学优秀成果奖一、二等奖获得者（前3位完成人）；
	</p>
	<p style="text-indent: 2em;">
		4．中华人民共和国职业技能大赛优胜奖（职工组、教师组、学生组），省职业技能大赛一等奖（学生组），省职业技能大赛二、三等奖获得者（职工组、教师组），市职业技能大赛一等奖（职工组、教师组）；
	</p>
	<p style="text-indent: 2em;">
		5．江西省中小学学科带头人；
	</p>
	<p style="text-indent: 2em;">
		6．符合市组织、人社部门发布的急需紧缺人才目录的各类人才；
	</p>
	<p style="text-indent: 2em;">
		7．相当于上述层次的人才。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			F类（行业英才）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．各行业具有中级专业技术职务任职资格（含职业农民中级职称）的专业技术人才，取得高级工以上职业资格证书（职业技能等级证书）的人才；
	</p>
	<p style="text-indent: 2em;">
		2．全日制大学本科学历及技工院校预备技师班毕业生人才（含教育部认可的境外高等院校毕业的归国留学人员）；
	</p>
	<p style="text-indent: 2em;">
		3．省职业技能大赛二、三等奖获得者（学生组），市职业技能大赛一等奖（学生组），市职业技能大赛二、三等奖（职工组、教师组）；
	</p>
	<p style="text-indent: 2em;">
		4．市厅级科学技术奖一、二、三等奖获得者（前3位完成人），且产生较大经济效益或社会效益；
	</p>
	<p style="text-indent: 2em;">
		5．参与制定或修订行业标准或地方标准或团体标准的人才（前3位完成人），完成1项市级以上科研项目、技术改造项目或企业重点项目且产生较大经济效益、社会效益的人才（前3位完成人）；
	</p>
	<p style="text-indent: 2em;">
		6．所从事领域具有一定专长，作出突出贡献，得到行业认可的人才；
	</p>
	<p style="text-indent: 2em;">
		7．企业聘请的年薪20万元以上管理人员、技术人员。
	</p>
	<p style="text-indent: 2em;">
		<strong>
			G类（产业能手）。
		</strong>
		主要包括：
	</p>
	<p style="text-indent: 2em;">
		1．大专、技工院校高级工班毕业后工作满3年，中专、技工院校中级工班毕业后工作满5年的人才；
	</p>
	<p style="text-indent: 2em;">
		2．取得初级工以上职业资格证书（职业技能等级证书）的人才，取得初级专业技术职务任职资格（含职业农民初级职称）的专业技术人才；
	</p>
	<p style="text-indent: 2em;">
		3．市职业技能大赛二、三等奖（学生组）；
	</p>
	<p style="text-indent: 2em;">
		4．主持1项以上技术研发和应用推广、科技成果转化及产业化且取得明显效益的项目主要负责人、技术主要负责人或质量主要负责人。
	</p>
	<p style="text-indent: 2em;">
		<br>
	</p>
	<p style="text-indent: 2em;">
		<br>
	</p>
</div>
  
  `

}

export default configDate
