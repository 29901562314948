import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// import 'material-design-icons-iconfont/dist/material-design-icons.css'

import zh from 'vuetify/es5/locale/zh-Hans'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    lang: {
        current: "zh",
        locales: {
            zh
        }
    },
    theme: {
        themes: {
            light: {
                primary: '#2196F3',
                secondary: '#2196F3',
                accent: '#d42517',
                error: '#fe4948',
                info: '#FFFFFF',
                success: '#4CAF50',
                warning: '#FFC107',
            }
        }
    }
});