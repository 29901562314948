let leix = sessionStorage.getItem("Leix") || ''

if (leix==='talent'){
    leix=0
}else if(leix==='enterprise'){
    leix=1
}else if(leix === 'admin'){
    leix=2
}else {
    leix =3
}

let Typeleia = [
    {
        path: '/main/talent',
        name: 'talent',
        component: () =>
            import ('../views/ControlView/Personal'),
        meta: {title: '个人'},
    },
    {
        path: '/main/enterprise',
        name: 'enterprise',
        component: () =>
            import ('../views/ControlView/Business'),
        meta: {title: '企业'},
    },
    {
        path: '/main/admin',
        name: 'admin',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau'),
        meta: {title: '人社局'},
    },
    {
        path: '/main/worthyManager',
        name: 'registration',
        component: () => import('../views/ControlView/PeopleclubBureau/registration'),
        meta: {title: '乡贤新增'}
    },
]

const configDate =[

    Typeleia[leix],
    //talent 个人
    {
        path: '/declarelist',
        name: 'declarelist',
        component: () =>
            import ('../views/ControlView/Personal/declarelist'),
        meta: {title: '申报管理'},
    },
    {
        path: '/declarefrom/:id',
        name: 'declarefrom',
        component: () =>
            import ('../views/ControlView/Personal/declarefrom'),
        meta: {title: '申报申请'},
    },
    {
        path: '/mydeclarexq/:id',
        name: 'mydeclarexq',
        component: () =>
            import ('../views/ControlView/Personal/mydeclarexq'),
        meta: {title: '申报详情'},
    },
    {
        path: '/probate',
        name: 'probate',
        component: () =>
            import ('../views/ControlView/Personal/probate'),
        meta: {title: '认证管理'},
    },
    {
        path: '/probatefrom/:id',
        name: 'probatefrom',
        component: () =>
            import ('../views/ControlView/Personal/probatefrom'),
        meta: {title: '个人认证'},
    },
    //enterprise 企业
    {
        path: '/qydeclarefrom/:id',
        name: 'qydeclarefrom',
        component: () =>
            import ('../views/ControlView/Business/declarefrom'),
        meta: {title: '申报申请'},
    },
    {
        path: '/qyprocessing',
        name: 'qyprocessing',
        component: () =>
            import ('../views/ControlView/Business/qyprocessing'),
        meta: {title: '企业认证'},
    },
    {
        path: '/examinelist',
        name: 'examinelist',
        component: () =>
            import ('../views/ControlView/Business/examinelist'),
        meta: {
            title: '人才审核',
            // keepAlive: true
        },
    },
    {
        path: '/examinewz/:id',
        name: 'examinewz',
        component: () =>
            import ('../views/ControlView/Business/examinewz'),
        meta: {title: '人才审核详情'},
    },
    {
        path: '/talentshenb',
        name: 'talentshenb',
        component: () =>
            import ('../views/ControlView/Business/talentshenb'),
        meta: {title: '人才申报'},
    },
    {
        path: '/meshenb',
        name: 'meshenb',
        component: () =>
            import ('../views/ControlView/Business/meshenb'),
        meta: {title: '自有申报'},
    },
    //admin
    {
        path: '/policylist',
        name: 'policylist',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/policylist'),
        meta: {title: '人才政策'},
    },
    {
        path: '/add/policy',
        name: 'add-policy',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/addPolicy'),
        meta: {title: '添加政策'},
    },
    {
        path: '/edit/policy',
        name: 'edit-policy',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/editPolicy'),
        meta: {title: '编辑政策'},
    },
    {
        path: '/selectionlist',
        name: 'selectionlist',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/selectionlist'),
        meta: {title: '人才列表'},
    },
    {
        path: '/selectionwz/:id',
        name: 'selectionwz',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/selectionwz'),
        meta: {title: '人才详情'},
    },
    {
        path: '/declareset',
        name: 'declareset',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/declareset'),
        meta: {title: '申报设置',},
    },
    {
        path: '/sbgoverning',
        name: 'sbgoverning',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/sbgoverning'),
        meta: {title: '审核管理'},
    },
    {
        path: '/sbgoverningwz/:id',
        name: 'sbgoverningwz',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/sbgoverningwz'),
        meta: {title: '申报管理详情'},
    },
    {

        path: '/feedback',
        name: 'feedback',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/feedback'),
        meta: {title: '申报反馈'},
    },
    {

        path: '/supervisetype',
        name: 'supervisetype',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/superviseType'),
        meta: {title: '类型管理'},
    },
    {

        path: '/feedbackDetails',
        name: 'feedbackDetails',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/feedbackDetails'),
        meta: {title: '反馈详情'},
    },
    {
        path: '/exmdiscuss',
        name: 'exmdiscuss',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/exmdiscuss'),
        meta: {title: '留言管理'},
    },
    {
        path: '/townsmen',
        name: 'townsmen',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/townsmen'),
        meta: {title: '乡贤管理'},
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/ControlView/PeopleclubBureau/register'),
        meta: {title: '登记管理'},
    },
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/ControlView/PeopleclubBureau/notice'),
        meta: {title: '通知公告'}
    },
    {
        path: '/add/notice',
        name: 'add-notice',
        component: () => import('../views/ControlView/PeopleclubBureau/noticed'),
        meta: {title: '添加公告'}
    },
    {
        path: '/edit/notice',
        name: 'edit-notice',
        component: () => import('../views/ControlView/PeopleclubBureau/noticeEdit'),
        meta: {title: '编辑公告'}
    },
    {
        path: '/edit/declare',
        name: 'edit-declare',
        component: () => import('../views/ControlView/PeopleclubBureau/policyEdit'),
        meta: {title: '编辑申报政策'}
    },
    {
        path: '/newpolicy',
        name: 'newpolicy',
        component: () => import('../views/ControlView/PeopleclubBureau/newpolicy'),
        meta: {title: '最新政策'}
    },
    {
        path: '/talent/policy/backstage',
        name: 'talent-policy-backstage',
        component: () => import('../views/ControlView/PeopleclubBureau/talentPolicy'),
        meta: {title: '人才政策'}
    },
    {
        path: '/talent/policy/add',
        name: 'talent-policy-add',
        component: () => import('../views/ControlView/PeopleclubBureau/addTalentPolicy'),
        meta: {title: '添加人才政策'}
    },
    {
        path: '/management',
        name: 'management',
        component: () =>
            import('../views/ControlView/PeopleclubBureau/management'),
        meta: {title: '人才管理'}
    },
    {
        path: '/business/:id',
        name: 'business',
        component: () =>
            import('../views/ControlView/PeopleclubBureau/business'),
        meta: {title: '企业详情'}
    },
    {
        path: '/townsmenwz/:id',
        name: 'townsmenwz',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/townsmenwz'),
        meta: {title: '乡贤详情'},
    },
    {
        path: '/registration',
        name: 'registration',
        component: () => import('../views/ControlView/PeopleclubBureau/registration'),
        meta: {title: '乡贤新增'}
    },
    {
        path: '/businessmgt',
        name: 'businessmgt',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/businessmgt'),
        meta: {title: '企业管理'},
    },
    {
        path: '/guided',
        name: 'guided',
        component: () =>
            import('../views/ControlView/PeopleclubBureau/guided'),
        meta: {title: '操作指南'}
    },
    {
        path: '/add/guide',
        name: 'add-guide',
        component: () =>
            import('../views/ControlView/PeopleclubBureau/addGuide'),
        meta: {title: '添加指南'}
    },
    {
        path: '/edit/guide',
        name: 'edit-guide',
        component: () =>
            import('../views/ControlView/PeopleclubBureau/editGuide'),
        meta: {title: '编辑指南'}
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/settings'),
        meta: {title: '账号管理'},
    },
    {
        path: '/rolelist',
        name: 'rolelist',
        component: () =>
            import ('../views/ControlView/PeopleclubBureau/rolelist'),
        meta: {title: '角色管理'},
    },

]
export default configDate